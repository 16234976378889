<template>
  <v-dialog v-model="ModaltermOfServices" max-width="85%">
    <v-card
     max-width="100%"
     max-height="100%"
     outlined
     dense
     scrollable
     color="#F2F2F2"
    >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalPopUp()"><v-icon large color="#848484">mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center" align="center">
            <h1 style="color: #55C0FC; text-decoration: underline;">ข้อตกลงการใช้ และ นโยบายความเป็นส่วนตัว</h1>
            <v-col cols="12">
              <p style="text-align: justify;">{{ TermofServices }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      ModaltermOfServices: false,
      TermofServices: ''
    }
  },
  created () {
    this.$EventBus.$on('ModalPopUp', this.ModalPopUp)
    this.GetTermofServices()
  },
  methods: {
    ModalPopUp () {
      this.ModaltermOfServices = !this.ModaltermOfServices
    },
    async GetTermofServices () {
      const response = await this.axios.get(`${process.env.VUE_APP_API}termOfServices`)
      if (response.data.response_status === "SUCCESS") {
        this.TermofServices = response.data.data.text
      }
    }
  }
}
</script>

<style>

</style>
