<template>
  <div>
    <v-overlay :value="loading">
      <v-card class="pa-6 ma-4" style="background-color: white">
        <v-row align="center" justify="center" dense>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="64"
              color="black"
            ></v-progress-circular>
          </v-col>

          <v-col cols="12" class="text-center">
            <span style="color: black"
              >กรุณารอสักครู่... ขณะนี้กำลังดาวน์โหลดระบบชำระเงิน</span
            >
            <br />
          </v-col>
          <v-col cols="12" class="text-center">
           <span style="color: red">x อย่ากดสั่งซื้อซ้ำ</span><br />
            <span style="color: red">x อย่าปิดหน้าจอ </span><br />
            <span style="color: red">x อย่ากดปุ่มรีเฟรช</span><br />
            <span style="color: red"
              >หมายเหตุ หากไม่สามารถสแกนจ่ายได้ใน 1 นาที
              กรุณาติดต่อเจ้าหน้าที่แอดมิน
              <a target="_blank" href="https://lin.ee/Ayc1oQR">คลิ๊กที่นี่</a>
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-overlay>
    <v-row align="center" justify="center" class="">
      <!-- <v-img
        max-width="350px"
        class="mx-auto mb-5"
        src="@/assets/logo/Powerball_logo_red.png"
      ></v-img> -->
      <v-col></v-col>
      <v-carousel
        cycle
        height="100%"
        hide-delimiter-background
        show-arrows-on-hover
        class="mb-4 mx-auto"
      >
        <v-carousel-item v-for="(item, i) in banners" :key="i">
          <v-img
            :src="item.imgUrl"
            :lazy-src="item.imgUrl"
            width="90%"
            height="100%"
            aspect-ratio="1"
            class="mx-auto mr-4"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <div class="text-center mt-6 mb-6">
      <br />
      <h1 style="font-weight: 700">งวดวันที่: {{ drawDate }}</h1>
      <h2 style="font-weight: 700; font-size: 0.9rem">
        กรณีสั่งซื้อและชำระเงินหลังเวลา 18:00 น. ของวันออกผลรางวัล
        ท่านอาจได้รับสลากเป็นของงวดถัดไปอัตโนมัติ
      </h2>
    </div>
    <div
      class="text-center ma-2 pa-4"
      style="padding: 20px; border-color: black; border: 1px; border: groove"
    >
      <v-row
        style="
          border-color: black;
          text-align: -webkit-center;
          align-items: center;
        "
      >
        <v-col cols="12" class="py-4">
          <v-img
            src="@/assets/logo/lao_side.png"
            max-width="270px"
            max-height="150px"
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          md="12"
          style="background: #fdc2c8"
          sm="12"
          class="text-left"
        >
          <v-btn color="#b80e53" @click="dialogHowtoLao()" dense
            ><v-icon large>mdi-info-outline</v-icon>
            <p style="font-size: 1rem; color: #fff" class="mt-4">
              วิธีเลือกซื้อ?
            </p></v-btn
          >
        </v-col>

        <v-col cols="12" class="mt-2">
          <v-row>
            <v-col cols="12">
              <v-row
                class="text-center"
                align="center"
                justify="center"
                no-gutters
              >
                <v-col cols="8">
                  <v-row align="center" justify="center">
                    <v-col cols="12" md="4" sm="4" xs="4">
                      <p style="margin-top: 10px">จำนวนตั๋วที่เลือกซื้อ</p>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <v-select
                        v-model="ticket"
                        class="pt-0"
                        outlined
                        dense
                        :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        suffix="ตั๋ว"
                        hide-details
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- <v-col
                  cols="12"
                  style="background: #fff"
                  class="text-right mb-1 pa-4"
                  sm="7"
                >
                  <v-row class="my-2">
                    <span style="font-size: 16px; font-weight: 700; color: #000"
                      >ใส่ตัวเลขด้วยตนเอง หรือ
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn color="#FDC2C8" @click="randomRow()">
                      <v-icon small>mdi-autorenew</v-icon
                      ><span style="font-size: 1rem">สุ่มตัวเลข</span></v-btn
                    >
                  </v-row>
                </v-col> -->

                <v-col cols="12">
                  <v-row
                    class="text-center"
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <!-- <v-col cols="8">
                      <v-row align="center" justify="center">
                        <v-col cols="6" md="4" sm="4" xs="4">
                          <p style="margin-top: 10px">ราคารวม</p>
                        </v-col>
                        <v-col cols="6" md="6" sm="6" xs="6">
                        
                          {{ ticket * 100 }} บาท
                        </v-col>
                      </v-row>
                    </v-col> -->
                  </v-row>
                </v-col>
                <!-- <v-col cols="12" class="mt-4">
                  <v-btn
                    @click="onClickAdd()"
                    large
                    color="#B80E53"
                    style="margin-top: -10px"
                    dark
                    >เพิ่มสลากชุดนี้</v-btn
                  >
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-col cols="12" style="background: #b80e53">
          <v-col cols="12">
            <h3 style="color: white">เลขสลากที่ท่านเลือกไว้แล้ว :</h3>
          </v-col>
          <v-col cols="12" v-if="slectedSet.length == 0">
            <h3 style="color: #f44336">ยังไม่ได้เลือก</h3>
          </v-col>
          <v-row
            style="padding: 5px"
            class="text-center"
            v-for="(i, index) in slectedSet"
            :key="index"
            no-gutters
            dense
          >
            <v-col cols="3" style="margin-left: -10px">
              <span style="font-size: 0.9rem">แถวที่ {{ index + 1 }}</span>
            </v-col>

            <v-col cols="4">
              <span
                v-for="(j, indexj) in i.set"
                :key="indexj"
                style="font-size: 0.9rem"
              >
                {{ j }}
              </span>
            </v-col>
            <v-col cols="4">
              <span style="font-size: 0.9rem"
                >ราคา {{ i.price * 100 }} กีบ <br />
                ({{ i.price }} บาท)</span
              >
            </v-col>
            <v-col cols="1" style="margin-left: 10px">
              <v-icon small @click="deleteRow(index)">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col cols="12">
          <!-- <v-img
            max-width="100px"
            class="mx-auto my-5"
            src="@/assets/logo/powerplay_btn.jpg"
          ></v-img> -->
        </v-col>
        <!-- <v-col cols="12" class="mt-4">
          <v-row justify="left">
            <p style="font-size: 1.2rem; font-weight: 700">ประเภทการสั่งซื้อ</p>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-4">
          <v-row justify="left" style="margin-top: -50px">
            <v-radio-group
              v-model="buyType"
              hide-details
              @change="onChangeMultipleAmt()"
            >
              <v-radio label="ซื้อครั้งเดียว" value="ONE_TIME"></v-radio>
              <v-radio label="เลือกซื้อหลายงวด" value="MULTIPLE"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-4" v-if="buyType == 'MULTIPLE'">
          <v-row justify="left" hide-details>
            <p style="font-size: 1.2rem; font-weight: 700">
              จำนวนงวด (รวมงวดนี้ด้วยแล้ว)
            </p>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="mt-0"
          style="margin-top: -10px"
          v-if="buyType == 'MULTIPLE'"
        >
          <v-row justify="left" dense no-gutters hide-details>
            <v-col cols="7" md="5" xs="6">
              <v-select
                v-model="multipleAmt"
                :items="itemsList"
                item-text="text"
                item-value="value"
                placeholder="กรุณาเลือกจำนวน"
                outlined
                dense
                hide-details
                @change="onChangeMultipleAmt()"
              ></v-select>
            </v-col>
          </v-row>
        </v-col> -->

        <v-col cols="12" class="mt-4">
          <br />
          <v-row justify="center" align="center" style="background: #fdc2c8">
            <v-col cols="12" class="ma-0 pa-0 px-2">
              <v-row align="center" dense>
                <v-col cols="12">
                  <v-radio-group
                    v-model="buyToFlag"
                    row
                    hide-details
                    @change="configbuyToFlag()"
                  >
                    <v-radio label="ซื้อให้ตัวเอง" :value="false"></v-radio>
                    <v-radio label="ซื้อให้คนอื่น" :value="true"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12"> </v-col>
                <v-col cols="6" class="px-1">
                  <v-text-field
                    outlined
                    dense
                    label="ชื่อ"
                    v-model="receiveFirstName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 px-1">
                  <v-text-field
                    outlined
                    dense
                    label="นามสกุล"
                    v-model="receiveLastName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 px-1">
                  <v-text-field
                    outlined
                    dense
                    label="เบอร์โทรศัพท์"
                    v-model="receiveTel"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 px-1">
                  <v-textarea
                    outlined
                    dense
                    label="ที่อยู่จัดส่ง"
                    v-model="receiveAddress"
                    rows="3"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="7" class="mt-4">
              <p style="font-size: 1.2rem; font-weight: 700; color: #000">
                ราคารวม
                <span>
                  <b>{{ totalPrice }}</b>
                </span>
                บาท
              </p>
            </v-col>
            <v-col cols="5" class="mt-4">
              <v-btn
                @click="confirmBuy()"
                large
                color="#B80E53"
                style="margin-top: -10px"
                :disabled="loading"
                dark
                >สั่งซื้อเลย</v-btn
              >
            </v-col>
          </v-row>
          <div
            v-if="paymentDetail"
            style="
              border: 1px solid white;
              margin-top: 30px;
              border-radius: 20px;
            "
          >
            <v-row>
              <!-- <v-row > -->
              <v-col cols="12">
                <br />
                <p><u>ช่องทางการชำระเงิน</u></p>
              </v-col>

              <v-toolbar color="cyan" flat>
                <v-tabs color="black">
                  <v-tabs-slider color=""></v-tabs-slider>
                  <v-tab
                    v-for="item in tabitems"
                    :key="item.key"
                    @click="tab(item)"
                    style="color: black"
                  >
                    <span style="font-size: 12px">{{ item.text }}</span>
                  </v-tab>
                </v-tabs>
              </v-toolbar>

              <v-row v-if="tabs == 'qrcode'">
                <v-col cols="12" class="mt-2"
                  >หมายเลขคำสั่งซื้อ: {{ transactionNo }}</v-col
                >
                <v-col cols="12" class="mt-2"
                  >ระยะเวลาในการแสกน QR code ภายใน:
                  <span style="color: #f44336">15 นาที</span>
                  <br />
                  หรือ กรุณาชำระเงินทันที</v-col
                >
                <v-col cols="12"><b>วิธีการสแกนจ่าย</b></v-col>
                <v-col cols="12"
                  >1. save บันทึกรูป QR Code เข้ามือถือหรือ
                  แคปเจอร์สกรีนรูปหน้าจอ</v-col
                >
                <v-col cols="12"
                  >2. เข้าแอพพลิเคชั่นธนาคาร แล้วเลือกสแกนจ่าย</v-col
                >
                <v-col cols="12"
                  >หมายเหตุ หากไม่สามารถสแกนจ่ายได้
                  กรุณาติดต่อเจ้าหน้าที่แอดมิน</v-col
                >
                <v-col cols="12"
                  ><span style="color: red"
                    >**หากไม่ได้รับ SMS สั่งซื้อสำเร็จใน 15-30 นาที
                    ออเดอร์อาจจะส่งคำสั่งซื้อไม่สำเร็จ
                    กรุณาติดตอเจ้าหน้าแอดมินทางไลน์ หรือ โทร. 02-114-1326**
                  </span></v-col
                >
               
                <v-col cols="12">
                  <v-img
                    max-width="300px"
                    class="mx-auto my-5"
                    :src="paymentDetail.image"
                  ></v-img>
                  <p id="qrcode">
                    ยอดชำระ: <b> {{ totalPrice }}</b> บาท
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="tabs == 'deposit'">
                <v-col cols="12" class="mt-2"
                  >หมายเลขคำสั่งซื้อ: {{ transactionNo }}</v-col
                >
                <v-col cols="12" class="mt-2"
                  >ระยะเวลาในการโอนเงิน:
                  <span style="color: #f44336">กรุณาชำระเงินทันที</span></v-col
                >
                <v-col cols="12">
                  <!-- <v-img
                    max-width="300px"
                    class="mx-auto my-5"
                    :src="paymentDetail.image"
                  ></v-img> -->

                  <v-row align="center" justify="center">
                    <p>หมายเลขบัญชี: กระแสรายวัน​ 468-1-08448-9</p>
                    <v-icon @click="copy()" class="mb-4 ml-2"
                      >mdi-clipboard-multiple-outline
                    </v-icon>
                  </v-row>

                  <p>ธนาคาร: SCB ธนาคารไทยพาณิชย์</p>
                  <p>ชื่อบัญชี: บริษัท มังกร ดิจิตอล จำกัด</p>
                  <p>
                    ยอดชำระ: <b> {{ totalPrice }}</b> บาท
                  </p>
                </v-col>
                <v-row align="center" justify="center" class="mb-2">
                  <v-col cols="10" md="10" sm="6" xs="12">
                    <v-card outlined>
                      <v-row no-gutters align="center" justify="center">
                        <v-col cols="12" class="pt-6">
                          <span>อัพโหลดรูปสลิปที่นี่</span>
                          <span style="color: red">*</span>
                        </v-col>
                        <!-- <v-spacer></v-spacer> -->
                        <v-col cols="11" class="mt-5 ma-10" id="step-3">
                          <v-row
                            align="center"
                            justify="center"
                            @click="changePic1()"
                          >
                            <v-img
                              v-model="picture_1"
                              src="@/assets/upload.png"
                              class="mr-3"
                              max-width="50"
                            >
                            </v-img>
                            <span>เลือกรูปภาพที่นี่</span>
                          </v-row>
                          <v-row align="center" justify="center" class="mt-4">
                            <v-col
                              cols="5"
                              md="5"
                              class="
                                pl-5
                                pr-5
                                pb-5
                                row
                                fill-height
                                sortable-list
                                align-center
                              "
                            >
                              <v-card dense light class="pa-1" v-if="showImage">
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-icon small light @click="RemoveImage()"
                                    >mdi-close</v-icon
                                  >
                                </v-card-actions>

                                <v-img
                                  :src="showImage"
                                  width="200px"
                                  aspect-ratio="0.9"
                                  contain
                                ></v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                          <input
                            type="file"
                            ref="image"
                            id="picTure1"
                            accept="image/png"
                            @change="showPicture1($event)"
                            style="display: none"
                        /></v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="uploadSlip()"
                      style="
                        background: linear-gradient(
                          180deg,
                          #fbe599 0%,
                          #d5ab61 100%
                        );
                      "
                    >
                      <!-- ยืนยันส่งหลักฐานการโอนเงิน</v-btn> -->
                      อัพโหลดสลิป</v-btn
                    >
                  </v-col>
                </v-row>
              </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <v-dialog v-model="dialogHowto">
      <v-card outlined width="98vw">
        <v-row align="center" justify="center" class="ma-1">
          <v-col cols="12" class="text-right">
            <v-btn text small dense @click="dialogHowto = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-img src="@/assets/howtohl.jpg"></v-img>
          </v-col>
          <v-col cols="12">
            <h2 style="font-weight: 700">วิธีการเลือกซื้อลอตเตอรี่ลาว</h2>
            <v-divider class="mb-2"></v-divider>
            <span style="font-size: 0.8rem"
              >ลูกค้าสามารถเลือกซื้อลอตเตอรี่รัฐบาลลาว (หวยลาวพัฒนา)
              ได้มากถึงสัปดาห์ละ 3 ครั้ง เนื่องจากมีการออกรางวัลทุกวันจันทร์,
              วันพุธ และวันศุกร์ โดยมีลำดับขั้นตอนการกดสั่งซื้อดังนี้
              <br />
              <b>1.</b> กดเลือกจำนวนหลักที่ต้องการเลือกซื้อ ระหว่าง แบบ 2
              ตัวท้าย, 3 ตัวท้าย, 4 ตัวท้าย, 5 ตัวท้าย, ไปจนถึง 6 หลัก ทั้งนี้
              ลอตเตอรี่ลาว จะไม่มีรางวัลเลขท้ายเพิ่มเติม
              <br />
              ลูกค้าจำเป็นจะต้องระบุประเภทการเลือกซื้อจำนวนเลขที่ที่ต้องการซื้อโดยเฉพาะเท่านั้น
              ตัวอย่างเช่น กรณีรางวัลที่ออก เป็นเลข 1 2 3 4 5 6
              <br />
              - ลูกค้าที่ซื้อเลข 123456 จะไม่สามารถได้รับรางว้ลเลขท้าย 2-3-4-5
              ตัว ได้ แต่จะสามารถรับรางวัลกรณีถูกตรงทั้ง 6 หลักเท่่านั้น
              <br />
              - ลูกค้าที่ซื้อเลข 23456 (5 ตัว) จะไม่สามารถได้รับรางว้ลเลขท้าย
              2-3-4 ตัว ได้ แต่จะสามารถรับรางวัลกรณีถูกตรงทั้ง 5 หลักเท่่านั้น
              <br />
              - ลูกค้าที่ซื้อเลข 3456 (4 ตัว) จะไม่สามารถได้รับรางว้ลเลขท้าย 2-3
              ตัว ได้ แต่จะสามารถรับรางวัลกรณีถูกตรงทั้ง 4 หลักเท่่านั้น
              <br />
              - ลูกค้าที่ซื้อเลข 456 (3 ตัว) จะไม่สามารถได้รับรางว้ลเลขท้าย 2
              ตัว ได้ แต่จะสามารถรับรางวัลกรณีถูกตรงทั้ง 3 หลักเท่่านั้น
              <br />
              - ลูกค้าที่ซื้อเลข 56 จะสามารถรับรางวัลกรณีถูกตรงทั้ง 2
              หลักได้เท่านั้น
              <br />
              <b>2.</b> พิมพ์หมายเลขที่ต้องการ ระหว่าง 0-9 ให้ครบทุกช่อง
              สามารถเป็นเลขซ้ำได้ จนครบตามจำนวนช่องที่เลือกไว้
              <br />
              <b>3.</b>
              เลือกราคาที่ต้องการซื้อต่อ 1 หมายเลข ระหว่าง 1,000 กีบ (ราคา 10
              บาท), 2,000 กีบ (ราคา 20 บาท) หรือ 3,000 กีบ (30 บาท)
              <br />
              <b>4.</b>
              เลขสลากที่ลูกค้าได้เลือกจะอยู่ด้านล่าง
              ลูกค้าสามารถกดเลือกตัวเลขเพิ่มได้ไม่จำกัดจำนวนชุด
              จนกว่าจะครบตามที่ตั้งใจซื้อ ขั้นต่ำ 5 หมายเลข (5 แถว) หรือ 50 บาท
              ต่อ 1 ออเดอร์คำสั่งซื้อ <br /><b>5.</b> ตรวจสอบหมายเลข และ กดปุ่ม
              "สั่งซื้อเลย"</span
            >
            <v-divider class="my-2"></v-divider>
            <v-btn
              @click="dialogHowto = false"
              color="primary"
              class="text-right"
              dense
              >กลับ</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ModaltermOfServices from "@/components/User/Modal/ModalTermofServices";
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      ticket: 1,
      loading: false,
      dialogHowto: false,
      isDisable1: true,
      isDisable2: true,
      isDisable3: true,
      isDisable4: true,
      isDisable5: true,
      isDisable6: true,
      selectedPrice: 10,
      itemsPriceList: [
        {
          name: "1000 กีบ (10 บาท)",
          value: 10,
        },
        {
          name: "2000 กีบ (20 บาท)",
          value: 20,
        },
        // {
        //   name: "3000 กีบ (30 บาท)",
        //   value: 30,
        // },
      ],
      rules: {
        counter: (value) => value.length <= 1 || "Max 1 character",
      },
      buyToFlag: false,
      receiveFirstName: "",
      receiveLastName: "",
      receiveTel: "",
      receiveAddress: "",
      tabitems: [
        {
          text: "ชำระโดย QR code",
          key: "qrcode",
        },
        // {
        //   text: "ชำระโดยการ โอนผ่านธนาคาร",
        //   key: "deposit",
        // },
      ],
      tabs: "qrcode",
      dialog: false,
      checkPrivacy: false,
      lazy: false,
      carts: [],
      userData: [],
      totalPrice: 0,
      totalAmount: 0,
      sumTotal: 0,
      address: "",
      subdistrict: "",
      district: "",
      province: "",
      postcode: "",
      delivery: "0",
      feePrice: 0,
      itemsimg: [
        {
          src: require("@/assets/banners/Asset113.png"),
        },
        {
          src: require("@/assets/banners/Asset115.png"),
        },
        {
          src: require("@/assets/banners/Asset116.png"),
        },
        {
          src: require("@/assets/banners/banner_aug_18.png"),
        },
      ],
      items: [
        { text: "ฝากลอตเตอรี่ไว้กับเรา", value: "0" },
        { text: "จัดส่งไปรษณีย์ EMS", value: "1" },
      ],
      Rules: {
        cardNo: [(v) => !!v || "กรุณาระบุหมายเลขบัตร"],
        cardName: [(v) => !!v || "กรุณาระบุชื่อผู้ถือเลขบัตร"],
        expireMonth: [(v) => !!v || "กรุณาระบุเดือนที่หมดอายุ"],
        expireYear: [(v) => !!v || "กรุณาระบุปีที่หมดอายุ"],
        ccv: [(v) => !!v || "กรุณาระบุ ccv"],
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      numbers: [],
      numbersRight: [],
      slectedSet: [],
      isDisabed: false,
      isPowerBall: true,
      aToz: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      isPowerPlay: false,
      totalPrice: 0,
      buyType: "6",
      itemsList: [
        // {
        //   text: "1 งวด",
        //   value: 1,
        // },
        {
          text: "2 งวด",
          value: 2,
        },
        {
          text: "3 งวด",
          value: 3,
        },
        {
          text: "4 งวด",
          value: 4,
        },
        {
          text: "5 งวด",
          value: 5,
        },
        {
          text: "6 งวด",
          value: 6,
        },
        {
          text: "7 งวด",
          value: 7,
        },
        {
          text: "8 งวด",
          value: 8,
        },
        {
          text: "9 งวด",
          value: 9,
        },
        {
          text: "10 งวด",
          value: 10,
        },
      ],
      paymentDetail: null,
      multipleAmt: 1,
      agentData: null,
      qrExpireTime: "",
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      resCreateTransactionData: null,
      transactionNo: "",
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      drawDate: "",
      banners: [],
    };
  },
  async created() {
    this.onChangeMultipleAmt();
    for (let index = 0; index < 10; index++) {
      this.numbers.push({
        number: index,
        isSelect: false,
      });
    }

    for (let index = 1; index < 27; index++) {
      this.numbersRight.push({
        number: index,
        isSelect: false,
      });
    }
    if (!localStorage.getItem("DDLotUser")) {
      await this.$swal.fire({
        icon: "error",
        text: "กรุณาเข้าสู่ระบบก่อนการสั่งซื้อ",
        showConfirmButton: true,
        // timer: 1500,
      });
      this.$router.push({ path: "/member" }).catch(() => {});
    }
    var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));

    const auth = {
      headers: { Authorization: `Bearer ${user.token}` },
    };
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/users/` + user.user.id,
      auth
    );
    console.log("responseUserresponseUserresponseUserresponseUser", response);
    this.userData = response.data.data;
    // var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    // console.log("firstName", user.firstName);
    // console.log("lastName", user.lastName);
    if (
      response.data.data.firstName == "" ||
      response.data.data.lastName == "" ||
      response.data.data.firstName == null ||
      response.data.data.lastName == null
    ) {
      await this.$swal.fire({
        icon: "error",
        text: "กรุณาระบุชื่อ- นามสกุล ก่อนสั่งซื้อ",
        showConfirmButton: true,
        // timer: 1500,
      });
      await this.$router.push({ path: "/member" }).catch(() => {});
    }
    this.carts = [];
    this.getCart();
    this.getAgents();
    await this.configbuyToFlag();
  },
  components: {
    ModaltermOfServices,
  },
  watch: {
    num6(val) {
      if (val) {
        this.$refs.num5.focus();
      }
    },
    num5(val) {
      if (val) {
        this.$refs.num4.focus();
      } else {
        this.$refs.num6.focus();
      }
    },
    num4(val) {
      if (val) {
        this.$refs.num3.focus();
      } else {
        this.$refs.num5.focus();
      }
    },
    num3(val) {
      if (val) {
        this.$refs.num2.focus();
      } else {
        this.$refs.num4.focus();
      }
    },
    num2(val) {
      if (val) {
        this.$refs.num1.focus();
      } else {
        this.$refs.num3.focus();
      }
    },
    num1(val) {
      if (!val) {
        this.$refs.num2.focus();
      }
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText("4681084489");
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    warningAlert(val) {
      if (val) {
        console.log("clickkkk");
        this.$swal.fire({
          icon: "error",
          text: "กรุณากรอกตัวเลขเฉพาะช่องที่ไม่มีเครื่องหมาย X",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    onClickAdd() {
      if (this.buyType == "2") {
        if (this.num1 !== "" && this.num2 !== "") {
          this.slectedSet.push({
            set: [this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "3") {
        if (this.num1 !== "" && this.num2 !== "" && this.num3 !== "") {
          this.slectedSet.push({
            set: [this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "4") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== ""
        ) {
          this.slectedSet.push({
            set: [this.num4, this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "5") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== "" &&
          this.num5 !== ""
        ) {
          this.slectedSet.push({
            set: [this.num5, this.num4, this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "6") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== "" &&
          this.num5 !== "" &&
          this.num6 !== ""
        ) {
          this.slectedSet.push({
            set: [
              this.num6,
              this.num5,
              this.num4,
              this.num3,
              this.num2,
              this.num1,
            ],
            price: this.selectedPrice,
          });
          this.num1 = "";
          this.num2 = "";
          this.num3 = "";
          this.num4 = "";
          this.num5 = "";
          this.num6 = "";

          // this.totalPrice = this.slectedSet.length * 150;
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      }

      this.totalPrice = 0;
      for (let index = 0; index < this.slectedSet.length; index++) {
        const element = this.slectedSet[index];
        this.totalPrice = this.totalPrice + element.price;
      }
    },
    focusNextOncePopulated(event, index) {
      console.log(event);
      // if (event.target.value.length === max) {
      const nextElement =
        this.$refs?.[`input-${Number(event.target.dataset.index) + 1}`];
      if (nextElement) nextElement.focus();
      // }
    },
    async configbuyToFlag() {
      const responseDrawDate = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/LAO_DATE`
      );
      console.log("LAO_DRAW_DATE", responseDrawDate);
      this.drawDate = responseDrawDate.data.data.value1;

      const responseBanners = await this.axios.get(
        `${process.env.VUE_APP_API}/banners?bannerType=หวยลาว`
      );
      console.log("responseBanners", responseBanners.data.data);
      this.banners = responseBanners.data.data;

      if (this.buyToFlag == false) {
        this.receiveFirstName = this.userData.firstName;
        this.receiveLastName = this.userData.lastName;
        this.receiveTel = this.userData.telNumber;
      } else if (this.buyToFlag == true) {
        this.receiveFirstName = "";
        this.receiveLastName = "";
        this.receiveTel = "";
      }
    },
    async uploadSlip() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      // window.open(`https://pay.sn/ddlottery/${this.totalPrice}`);
      console.log(user);

      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      if (this.pic1 !== "") {
        var updateParam = {
          status: "รอคอนเฟิร์ม",
          slipImg: this.pic1,
        };
        console.log(updateParam);

        const resUpdateTransaction = await this.axios.post(
          `${process.env.VUE_APP_API}/transactionLotteryjapan/updateLao/${this.resCreateTransactionData.id}`,
          updateParam,
          auth
        );

        this.$swal
          .fire({
            icon: "success",
            title: "การสั่งซื้อสำเร็จ",
            text: " หากลูกค้าไม่ได้รับ sms ยืนยันออเดอร์ใน 1 ชม. ให้ติดต่อสอบถามเจ้าหน้าที่แอดมินทันที",
            showConfirmButton: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$router.push({ path: "/safe" }).catch(() => {});
            }
          });
      } else {
        this.$swal.fire({
          icon: "error",
          text: "กรุณาอัพโหลดรูปภาพ",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = "";
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    tab(val) {
      if (val.key == "deposit") {
        this.tabs = "deposit";
      }
      if (val.key == "qrcode") {
        this.tabs = "qrcode";
      }
    },
    async onClickBuy() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      // window.open(`https://pay.sn/ddlottery/${this.totalPrice}`);
      console.log(user);
      const headers = {
        authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImEyOGY4YmUzM2NhY2MzNTBlZjUxZTYxNTM5NmYxMmM4ODkzNTM2ZWE4N2I1ODdmZGZhMTc1OTMwNmRhYjcyNWEzZmYxOTAxYzAzYzE5YjcyIn0.eyJhdWQiOiIyIiwianRpIjoiYTI4ZjhiZTMzY2FjYzM1MGVmNTFlNjE1Mzk2ZjEyYzg4OTM1MzZlYTg3YjU4N2ZkZmExNzU5MzA2ZGFiNzI1YTNmZjE5MDFjMDNjMTliNzIiLCJpYXQiOjE2NDAzOTE0MTEsIm5iZiI6MTY0MDM5MTQxMSwiZXhwIjoxNzk4MTU3ODExLCJzdWIiOiI5NzIzNDE5MiIsInNjb3BlcyI6W119.rkcfL1dimu68gcNVFwZJZV3Jc23dxPIra66BYmN-iMofEzxnqZZ51iJCAw8EEMhWuvbmU_vK1wHsWwFDiibf-RdhQDmOjuIS5uShWu62aKCKutmE39xBXN08aeEeoC0DTE13SkBqlVyzYYemxcy_NQfZbUz8szo5Ec0VOnHtM_QmWm-VN6zXPn8dRSAAbSITp10oKMMtg2sgU5KCrKCdGW3httlJax1AiFDTKjKtGWrW-bYftXPtzZpgBQ74qbtfx04j3HYFu0zcrBMUy6kaebM2HL6JHXYuAyqvpCDOqJbE9_1JuewcqIXQorIl8iUd5E8eojlhgR0KWGoBxcXGx5CuR0871D4Lly243SD3KByBscCrVjaIWGQHuqkjdVaiiWHSTrDR_mORy_qFts6Umr-5goG30psDCez4SG6-un89MGd_OxA9DYeFg1FL8RqiyIjrMU44dhVWjN4EfZ_KWscrId9tPHElJ2eNr7My6d8jIbkijrlRPTtd9s6iQLW--IXzw3MfAm4lVvMhgM3CkNPJi1-rfeeNYwBUliS3hKLDXPJYoyCN9amc0UjCGA01zkY10QD5aE59Yx4BL2kT_nosMoYJafM7dTVU8DXAx6O0PIuuGKlohWJ2udzs1vqcUb6pXav25gWSSdlRHepWJH_WrH17t-99se6G-YR2JYQ",
        accept: "application/json",
      };
      const responseConfig = await this.axios.post(
        `https://apis.paysolutions.asia/tep/api/v1/promptpay?merchantID=97234192&productDetail=POWERBALL_JAPAN&customerEmail=ittiwut.wong%40gmail.com&customerName=${user.user.firstName} ${user.user.lastName}&total=${this.totalPrice}.00&referenceNo=002030${user.user.id}`,
        {},
        {
          headers: headers,
        }
      );
      console.log("payment Detail", responseConfig.data.data);
      this.paymentDetail = responseConfig.data.data;
    },
    async clickNumber(number) {
      console.log(number);
      number.isSelect = !number.isSelect;
      if (number.isSelect) {
        console.log(this.slectedSet);
        var selectedSize = this.slectedSet.length;
        if (selectedSize == 0) {
          this.slectedSet.push({
            set: [number.number],
          });
        } else {
          var set = this.slectedSet[selectedSize - 1].set;

          if (set.length < 5) {
            set.push(number.number);
            this.slectedSet[selectedSize - 1].set = set;
            // this.slectedSet[selectedSize - 1].powerBall = null;
          } else {
            this.slectedSet.push({
              set: [number.number],
              // powerBall: null,
            });
            console.log("เลือกสำเร็จ");
          }
          if (this.slectedSet[this.slectedSet.length - 1].set.length == 5) {
            if (
              this.slectedSet[this.slectedSet.length - 1].set.length == 5 &&
              this.slectedSet[this.slectedSet.length - 1].powerBall
            ) {
              this.numbers.forEach((num) => {
                num.isSelect = false;
              });

              this.numbersRight.forEach((num) => {
                num.isSelect = false;
              });

              await this.$swal.fire({
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                icon: "success",
                html: "เลือกลอตเตอรี่สำเร็จ 1 ชุด <br/> กรุณาดูรายการที่เลือกทางด้านล่าง",
              });
              this.isDisabed = false;
            } else {
              this.isDisabed = true;
            }
          } else {
            this.isDisabed = false;
          }
        }

        var powerPlayPrice = 0;

        if (this.isPowerPlay) {
          powerPlayPrice = this.slectedSet.length * 150;
        }

        this.totalPrice =
          (this.slectedSet.length * 150 + powerPlayPrice) * this.multipleAmt;
      } else {
        this.isDisabed = false;
        var index = this.slectedSet[this.slectedSet.length - 1].set.indexOf(
          number.number
        );
        if (index !== -1) {
          this.slectedSet[this.slectedSet.length - 1].set.splice(index, 1);
        }
      }
    },
    onChangeMultipleAmt(amt) {
      console.log(amt);
      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.num4 = "";
      this.num5 = "";
      this.num6 = "";

      this.buyType = amt;
      // this.buyType = "6";

      if (this.buyType == "2") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = true;
        this.isDisable4 = true;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "3") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = true;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "4") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "5") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = false;
        this.isDisable6 = true;
      } else if (this.buyType == "6") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = false;
        this.isDisable6 = false;
      }
    },
    onClickPowerPlay() {
      var powerPlayPrice = 0;

      if (this.isPowerPlay) {
        powerPlayPrice = this.slectedSet.length * 150;
      }

      this.totalPrice =
        (this.slectedSet.length * 150 + powerPlayPrice) * this.multipleAmt;
    },
    async clickPowerBallNumber(number) {
      //  var set = this.slectedSet[selectedSize - 1].set;
      this.numbersRight.forEach((numRight) => {
        numRight.isSelect = false;
      });
      // console.log(number);
      number.isSelect = !number.isSelect;
      if (number.isSelect) {
        console.log(number);
        var selectedSize = this.slectedSet.length;
        if (selectedSize == 0) {
          this.slectedSet.push({
            set: [],
            powerBall: number.number,
          });
        } else {
          var set = this.slectedSet[selectedSize - 1].set;
          this.slectedSet[selectedSize - 1].powerBall = number.number;
          if (set.length == 5 && this.slectedSet[selectedSize - 1].powerBall) {
            this.numbers.forEach((num) => {
              num.isSelect = false;
            });

            await this.$swal.fire({
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              icon: "success",
              html: "เลือกลอตเตอรี่สำเร็จ 1 ชุด <br/> กรุณาดูรายการที่เลือกทางด้านล่าง",
            });

            this.numbersRight.forEach((num) => {
              num.isSelect = false;
            });
            this.isDisabed = false;
          }
        }

        // console.log(this.selectedSize[selectedSize - 1])
      }
    },
    async checkPrivacyNa() {
      if (this.checkPrivacy == false) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณายอมรับข้อกำหนดและเงื่อนไขบริการ",
        });
      }
    },
    async deleteRow(index) {
      this.slectedSet.splice(index, 1);
      // this.totalPrice = this.slectedSet.length * 150;
      this.totalPrice = 0;
      for (let index = 0; index < this.slectedSet.length; index++) {
        const element = this.slectedSet[index];
        this.totalPrice = this.totalPrice + element.price;
      }
    },
    generateRandom(min, max, failOn) {
      failOn = Array.isArray(failOn) ? failOn : [failOn];
      var num = Math.floor(Math.random() * (max - min + 1)) + min;
      return failOn.includes(num) ? generateRandom(min, max, failOn) : num;
    },
    randomRow() {
      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.num4 = "";
      this.num5 = "";
      this.num6 = "";

      if (this.buyType == "2") {
        this.num2 = this.generateRandom(0, 9, 10);
        this.num1 = this.generateRandom(0, 9, 10);
      } else if (this.buyType == "3") {
        this.num3 = this.generateRandom(0, 9, 10);
        this.num2 = this.generateRandom(0, 9, 10);
        this.num1 = this.generateRandom(0, 9, 10);
      } else if (this.buyType == "4") {
        this.num4 = this.generateRandom(0, 9, 10);
        this.num3 = this.generateRandom(0, 9, 10);
        this.num2 = this.generateRandom(0, 9, 10);
        this.num1 = this.generateRandom(0, 9, 10);
      } else if (this.buyType == "5") {
        this.num5 = this.generateRandom(0, 9, 10);
        this.num4 = this.generateRandom(0, 9, 10);
        this.num3 = this.generateRandom(0, 9, 10);
        this.num2 = this.generateRandom(0, 9, 10);
        this.num1 = this.generateRandom(0, 9, 10);
      } else if (this.buyType == "6") {
        this.num6 = this.generateRandom(0, 9, 10);
        this.num5 = this.generateRandom(0, 9, 10);
        this.num4 = this.generateRandom(0, 9, 10);
        this.num3 = this.generateRandom(0, 9, 10);
        this.num2 = this.generateRandom(0, 9, 10);
        this.num1 = this.generateRandom(0, 9, 10);
      }
    },
    dialogHowtoLao() {
      this.dialogHowto = true;
    },
    clickRandomRowQuick() {
      this.randomRowQuick();
      // this.randomRowQuick();
      // this.randomRowQuick();
    },
    async randomRowQuick() {
      // console.log(index);
      // console.log(this.slectedSet);
      var randomSet = {
        set: [],
        powerBall: this.generateRandom(1, 26, null),
      };
      // this.slectedSet[index].set = [];
      randomSet.set.push(this.generateRandom(1, 69, randomSet.set));
      randomSet.set.push(this.generateRandom(1, 69, randomSet.set));
      randomSet.set.push(this.generateRandom(1, 69, randomSet.set));
      randomSet.set.push(this.generateRandom(1, 69, randomSet.set));
      randomSet.set.push(this.generateRandom(1, 69, randomSet.set));

      this.slectedSet.push(randomSet);

      this.onClickPowerPlay();
      await this.$swal.fire({
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: "success",
        html: "เลือกลอตเตอรี่สำเร็จ 1 ชุด <br/> กรุณาดูรายการที่เลือกทางด้านล่าง",
      });
    },
    dialogPrivacy() {
      this.dialog = true;
    },
    home() {
      this.$router.push("/");
    },
    payment() {
      if (this.$refs.form1.validate(true)) {
        const paymcartsentData = {
          isDelivery: this.delivery,
          address: {
            address: this.address,
            subDistrict: this.subdistrict,
            district: this.district,
            province: this.province,
            postcode: this.postcode,
          },
        };
        localStorage.setItem("paymcartsentData", encode(paymcartsentData));
        this.$router.push("payment");
      } else {
        alert("กรุณาเลือกตจัดส่ง");
      }
    },
    async getAgents() {
      // let agentCode = this.$route.query.agentCode;
      var agentCode = localStorage.getItem("agentCode");
      console.log("agentCode", agentCode);
      localStorage.setItem("agentCode", agentCode);
      this.agentCode = agentCode;
      if (agentCode) {
        const agentResponse = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/agentCount/` + agentCode
        );
        console.log(agentResponse.data);
        if (agentResponse.data.response_status == "SUCCESS") {
          this.agentData = agentResponse.data.data;
        }
        console.log(this.agentData);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/` + this.agentCode
        );
        this.agentData = response.data.data;
        console.log("response", response.data.data);
      }
    },
    async confirmBuy(val) {
      if (!localStorage.getItem("DDLotUser")) {
        await this.$swal.fire({
          icon: "error",
          text: "กรุณาเข้าสู่ระบบก่อนการสั่งซื้อ",
          showConfirmButton: true,
          // timer: 1500,
        });
        this.$router.push({ path: "/member" }).catch(() => {});
      }
      console.log(val);
      if (this.slectedSet.length >= 5) {
        // if (
        //   (this.buyType == "MULTIPLE" && this.multipleAmt != "") ||
        //   this.buyType == "ONE_TIME"
        // ) {
        this.$swal({
          text: "ยืนยันการสั่งซื้อใช่หรือไม่?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ยืนยัน",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
            // window.open(`https://pay.sn/ddlottery/${this.totalPrice}`);
            console.log(user);

            var buyParam = {
              userId: user.user.id,
              numberData: this.slectedSet,
              totalPrice: this.totalPrice,
              isPowerPlay: this.isPowerPlay,
              buyType: this.buyType,
              multipleAmt: this.multipleAmt,
              buyToFlag: this.buyToFlag,
              receiveFirstName: this.receiveFirstName,
              receiveLastName: this.receiveLastName,
              receiveTel: this.receiveTel,
              drawDate: this.drawDate,
            };
            if (this.agentData) {
              buyParam.agentId = this.agentData.id;
            }
            console.log(buyParam);

            const auth = {
              headers: { Authorization: `Bearer ${user.token}` },
            };
            const resCreateTransaction = await this.axios.post(
              `${process.env.VUE_APP_API}/transactionLotteryjapan/createLao`,
              buyParam,
              auth
            );

            console.log("resCreateTransaction", resCreateTransaction.data.data);

            this.resCreateTransactionData = resCreateTransaction.data.data;
            this.transactionNo = resCreateTransaction.data.transactionNo;

            const headers = {
              authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImEyOGY4YmUzM2NhY2MzNTBlZjUxZTYxNTM5NmYxMmM4ODkzNTM2ZWE4N2I1ODdmZGZhMTc1OTMwNmRhYjcyNWEzZmYxOTAxYzAzYzE5YjcyIn0.eyJhdWQiOiIyIiwianRpIjoiYTI4ZjhiZTMzY2FjYzM1MGVmNTFlNjE1Mzk2ZjEyYzg4OTM1MzZlYTg3YjU4N2ZkZmExNzU5MzA2ZGFiNzI1YTNmZjE5MDFjMDNjMTliNzIiLCJpYXQiOjE2NDAzOTE0MTEsIm5iZiI6MTY0MDM5MTQxMSwiZXhwIjoxNzk4MTU3ODExLCJzdWIiOiI5NzIzNDE5MiIsInNjb3BlcyI6W119.rkcfL1dimu68gcNVFwZJZV3Jc23dxPIra66BYmN-iMofEzxnqZZ51iJCAw8EEMhWuvbmU_vK1wHsWwFDiibf-RdhQDmOjuIS5uShWu62aKCKutmE39xBXN08aeEeoC0DTE13SkBqlVyzYYemxcy_NQfZbUz8szo5Ec0VOnHtM_QmWm-VN6zXPn8dRSAAbSITp10oKMMtg2sgU5KCrKCdGW3httlJax1AiFDTKjKtGWrW-bYftXPtzZpgBQ74qbtfx04j3HYFu0zcrBMUy6kaebM2HL6JHXYuAyqvpCDOqJbE9_1JuewcqIXQorIl8iUd5E8eojlhgR0KWGoBxcXGx5CuR0871D4Lly243SD3KByBscCrVjaIWGQHuqkjdVaiiWHSTrDR_mORy_qFts6Umr-5goG30psDCez4SG6-un89MGd_OxA9DYeFg1FL8RqiyIjrMU44dhVWjN4EfZ_KWscrId9tPHElJ2eNr7My6d8jIbkijrlRPTtd9s6iQLW--IXzw3MfAm4lVvMhgM3CkNPJi1-rfeeNYwBUliS3hKLDXPJYoyCN9amc0UjCGA01zkY10QD5aE59Yx4BL2kT_nosMoYJafM7dTVU8DXAx6O0PIuuGKlohWJ2udzs1vqcUb6pXav25gWSSdlRHepWJH_WrH17t-99se6G-YR2JYQ",
              accept: "application/json",
            };

            var userEmail = "order%40ddlottery.com";

            if (user.user.email !== "" && user.user.email) {
              userEmail = user.user.email.replace("@", "%40");
            }

            // this.totalPrice = 1;

            const responseConfig = await this.axios.post(
              `https://apis.paysolutions.asia/tep/api/v1/promptpay?merchantID=97234192&productDetail=LAO&customerEmail=${userEmail}&customerName=${user.user.firstName} ${user.user.lastName}&total=${this.totalPrice}.00&referenceNo=0${resCreateTransaction.data.data.id}`,
              {},
              {
                headers: headers,
              }
            );
            console.log("payment Detail", responseConfig.data.data);
            this.paymentDetail = responseConfig.data.data;

            // var date = new Date(
            //   responseConfig.data.data.expiredate
            //     .replace("/Date(", "")
            //     .replace(")/", "") * 1000
            // );
            // console.log(date);
            // // Hours part from the timestamp
            // var hours = date.getHours();
            // // Minutes part from the timestamp
            // var minutes = "0" + date.getMinutes();
            // // Seconds part from the timestamp
            // var seconds = "0" + date.getSeconds();

            // // Will display time in 10:30:23 format
            // this.qrExpireTime = hours + ":" + minutes.substr(-2);

            // console.log(this.qrExpireTime);

            var updateParam = {
              paysolutionId: this.paymentDetail.orderNo,
            };
            console.log(updateParam);

            const resUpdateTransaction = await this.axios.post(
              `${process.env.VUE_APP_API}/transactionLotteryjapan/updateLao/${resCreateTransaction.data.data.id}`,
              updateParam,
              auth
            );
            var that = this;
            const interval = setInterval(async function () {
              // method to be executed;
              const result = await that.axios.get(
                `${process.env.VUE_APP_API}/transactionLotteryjapan/checkPaysolutionStatusLAO/${resCreateTransaction.data.data.id}`
              );
              if (result.data.response_status == "SUCCESS") {
                clearInterval(interval);
                that.$swal
                  .fire({
                    icon: "success",
                    title: "การสั่งซื้อสำเร็จ",
                    text: " หากลูกค้าไม่ได้รับ sms ยืนยันออเดอร์ใน 1 ชม. ให้ติดต่อสอบถามเจ้าหน้าที่แอดมินทันที",
                    showConfirmButton: true,
                  })
                  .then(async (result) => {
                    if (result.isConfirmed) {
                      that.loading = false;
                      that.$router.push({ path: "/safe" }).catch(() => {});
                    }
                  });
              }
            }, 5000);
            this.loading = false;
            await this.$swal.fire({
              icon: "success",
              text: "กรุณาชำระเงินด้านล่าง",
              showConfirmButton: false,
              timer: 1000,
            });
            await document.getElementById("qrcode").focus();
            console.log('id="qrcode"');
            await window.scrollTo(0, document.body.scrollHeight);
            this.loading = false;
          }
        });
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     text: "กรุณาเลือกจำนวนงวด",
        //     showConfirmButton: false,
        //     timer: 1000,
        //   });
        // }
      } else {
        this.$swal.fire({
          icon: "error",
          text: "กรุณาเลือกอย่างน้อย 5 แถว",
          showConfirmButton: false,
          timer: 1000,
        });
        this.loading = false;
      }
    },
    async deleteCart(val) {
      console.log(val);
      this.$swal({
        text: "คุณต้องการลบลอตเตอรี่ที่เลือกใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ลบลอตเตอรี่ออกจากตะกร้า",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/cart/delete/` + val,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบลอตเตอรี่สำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          this.totalPrice = 0;
          this.totalAmount = 0;
          await this.getCart();
        }
      });
    },
    async getCart() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // console.log("user", user.token);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
        auth
      );
      console.log("response", response.data.data);
      this.carts = response.data.data;
      this.$store.dispatch("ActionsSetCars", response.data.data);

      const responseConfig = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/OPERATION_PRICE`,
        auth
      );
      console.log("responseConfig", responseConfig.data.data);
      this.feePrice = responseConfig.data.data.value1 * 1;

      this.carts.forEach((cart) => {
        cart.lottery.urlImage = cart.lottery.urlImage.replace(
          "20210425",
          "20210424"
        );
        this.totalPrice =
          this.totalPrice +
          (cart.lottery.price + responseConfig.data.data.value1 * 1) *
            cart.amount;
        this.totalAmount = this.totalAmount + cart.amount;
      });
      console.log("carts", this.carts);
      console.log(this.totalPrice);
      console.log(this.totalAmount);

      // this.lotteries = response.data.data;
      // this.lotteries.forEach((lot) => {
      //   lot.urlImage = lot.urlImage.replace("20210425", "20210424");
      //   console.log(lot.urlImage);
      // });
    },
    // async addToCart(lotto) {
    //   if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
    //     this.StatusLogin = true;
    //     var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    //     // this.Username = `${user.user.firstName} ${user.user.lastName}`;
    //     console.log(user);

    //     const data = {
    //       lotteryId: lotto.id,
    //       amount: 1,
    //       userId: user.user.id,
    //     };

    //     console.log(data);

    //     const auth = {
    //       headers: { Authorization: `Bearer ${user.user.token}` },
    //     };
    //     const response = await this.axios.post(
    //       `${process.env.VUE_APP_API}/cart/create`,
    //       // "http://localhost:8081/cart/create",
    //       data
    //       // auth
    //     );
    //     console.log("response", response.data.data);
    //     if (response.data.response_status === "SUCCESS") {
    //       this.$router.push({ path: "/cart" }).catch(() => {});
    //     }
    //   } else {
    //     this.StatusLogin = false;
    //     this.$router.push({ path: "/member" }).catch(() => {});
    //   }
    // },
  },
};
</script>
<style scoped>
.sec-cart {
  border-radius: 8px;
  padding: 8px;
  background: #00739c;
  margin: 0 -12px 20px;
  color: #fff;
}
.info2 {
  width: 15%;
  height: 30px;
  margin-top: -39px;
  margin-left: 170px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  /* min-width: 5%;
  max-height: 10%; */
  background: #f3e0bf73;
  backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}

.white--text /deep/ label {
  color: #fff;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: 200 !important;
  display: flex !important;
  align-items: center !important;
}

/* .lotto-group {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 47%;
  z-index: 20;
  background-color: #e8dfc4;
}

.lotto-scroll {
  width: 100%;
  overflow-x: scroll;
  overflow-y: ;
  display: flex;
  -webkit-overflow-scrolling: touch;
}
.lotto-scroll .lotto-group {
  display: flex;
  width: auto;
  margin: 0 20px 0 0;
  padding: 0 0 10px;
  background: 0 0;
} */
/* .lotto-group .info1 {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 60px;
  max-height: 120px;
  background: rgba(255, 94, 239, 0.85);
  backdrop-filter: blur(4px);
  text-align: center;
  color: white;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
  font-size: 10px;
  padding: 10px 0;
} */
/* .lotto-scroll .lotto-group .pic {
  display: flex;
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  overflow: unset;
  width: auto;
  align-items: flex-start;
} */
.sizeimg {
  width: 40vw;
}

#chips-container .v-chip.selected {
  background: #fff68b;
}

.v-text-field input {
  text-align: center !important;
}
.centered-input >>> input {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
}
</style>
